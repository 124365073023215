.modal-container{
    position: fixed;
    top: 0;
    left:0;
    width:100%;
    height:100%;
    z-index:2000;
    background-color: rgba(0,0,0,.5);
    opacity: 0;
    visibility:hidden;
    transition: all ease 0.7s;
}

.modal-container-active{
    opacity: 1;
    visibility: visible;
}

.modal-container .modal-content{
    width:400px;
    height:auto;
    margin: 15% auto;
    background-color: #FFFFFF;
    border-radius:4px;
    overflow:hidden;
}

.modal-container .modal-content .modal-heading{
    background-color: #4C8BCD;
}

.modal-container .modal-content .modal-heading h3{
    color: #fff;
    font-size:14px;
    font-weight:bold;
    text-align:center;
    margin:0;
    padding:10px;
}

.modal-container .modal-content .modal-body{
    font-size: 12px;
    color: #252525;
    padding-bottom:11px;
}

.modal-container .modal-content .modal-body div{
    margin-top: 7px;
}

.modal-container .modal-content .modal-body p{
    margin-top: 10px;
}

.modal-container .modal-content .modal-body input , .modal-container .modal-content .modal-body select{
    padding: 5px;
    border-radius: 4px;
    margin:4px;
    transition: all ease 1s;
}

.modal-container .modal-content .modal-body input.error-input{
    border: 1px solid red;
}

.modal-container .modal-content .modal-body .error{
    display:block;
    width:100%;
    text-aling:center;
    color: red;
    font-size: 10px;
    float: left;
}

.modal-container .modal-content .modal-footer{
    background-color: #EBEAEA;
    padding: 7px;
    overflow:hidden;
}

.modal-container .modal-content .modal-footer .btn-acept{
     background-color: #4C8BCD;
     color: #FFFFFF;
     float:right;
     border-radius:4px;
     padding:6px;
     cursor:pointer;
 }

.modal-container .modal-content .modal-footer .btn-cancel{
    background-color: #FFFFFF;
    color: #000000;
    margin-right:4px;
    float:right;
    border-radius:4px;
    padding:6px;
    cursor:pointer;
}